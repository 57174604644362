const openMenu = document.getElementById("openMenu");
const closedBtn = document.getElementById("closedBtn");
const menu = document.getElementById("menuWrapper");

openMenu.addEventListener("click", () => {
    menu.classList.remove("close");
    menu.classList.add("open");
});

closedBtn.addEventListener("click", () => {
    menu.classList.remove("open");
    menu.classList.add("close");
});


// Slider
$('.responsive').slick({
        dots: false,
        infinite: true,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 2000,
        pauseOnFocus: true,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 992,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 1
            }
          },
          {
            breakpoint: 540,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1
            }
          }
        ]
    });